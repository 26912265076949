<template>
  <div>
    <template v-if="group">
      <custom-breadcrumb
        :pageTitle="$t('Group')"
        :breadcrumb="breadcrumb"
      ></custom-breadcrumb>

      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <info-card 
            :school="school"
            :group="group"
            @refetch-data="getData(group.id)"
          />

          <b-card
            no-body
            class="card-employee-task"
          >
            <b-card-header>
              <b-card-title>{{ $t('forms.linked_teachers')}}</b-card-title>
              
              <b-button variant="link" class="pointer p-0"
              :to="{ 
                    name: 'school-groups-edit', 
                    params: { 
                      id: school.id, 
                      student_id: group.id, 
                      tab:1
                    } 
                  }">
                <feather-icon
                  icon="PlusIcon"
                  size="18"
                  class="cursor-pointer"
                />
                {{ $t('forms.link_teacher')}}
              </b-button>
            </b-card-header>

            <!-- body -->
            <b-card-body>
              <template v-for="(user) in group.users">
              <div
                v-if="user.linked"
                :key="user.user_id"
                class="employee-task d-flex justify-content-between align-items-center"
              >
                <b-media no-body>
                  <b-media-aside class="mr-75">
                    <b-avatar
                      size="42"
                      :text="avatarText(user.name)"
                      variant="light-primary"
                    />
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h6 class="mb-0">
                      {{ user.name }}
                    </h6>
                    <small>{{ user.email }}</small>
                  </b-media-body>
                </b-media>
                <div class="d-flex align-items-center pointer" 
                  v-b-tooltip.hover.left="$t('forms.unlink_teacher')"
                  @click="removeUser(user.id)"
                >
                  <small class="m-75" style="color:tomato;">
                    <font-awesome-icon :icon="['fas', 'unlink']"
                    size="lg" />
                  </small>
                </div>
              </div>
              </template>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <b-card
            no-body
            class="card-employee-task"
          >
            <b-card-header>
              <b-card-title>{{$t('Dreamers')}}</b-card-title>
              <b-button variant="link" class="pointer p-0"
              :to="{ 
                    name: 'school-groups-edit', 
                    params: { 
                      id: school.id, 
                      student_id: group.id, 
                      tab:2
                    } 
                  }">
                <feather-icon
                  icon="PlusIcon"
                  size="18"
                  class="cursor-pointer"
                />
                {{ $t('forms.add_model', {model: $t('models.dreamer')})}}
              </b-button>
            </b-card-header>

            <!-- body -->
            <b-card-body>
              <template v-for="dreamer in group.dreamers">
              <div
                v-if="dreamer.linked"
                :key="dreamer.id"
                class="employee-task d-flex justify-content-between align-items-center"
              >
                <b-media no-body>
                  <b-media-aside class="mr-75">
                    <b-avatar
                      size="42"
                      :src="require('@/assets/images/avatar/AvatarSprite_'+(dreamer.avatar||'57')+'.png?v4')"
                    />
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h6 class="mb-0">
                      <b-link 
                        :to="{ name: 'school-dreamers-view', params: { 
                          id: school.id,
                          dreamer_id: dreamer.id 
                        } }"
                      >
                        {{ dreamer.name }}
                      </b-link>
                    </h6>
                    <small class="text-muted ">{{ dreamer.age }} {{$t('fields.years_old')}}</small>
                  </b-media-body>
                </b-media>
                <div class="d-flex align-items-center pointer" 
                  v-b-tooltip.hover.left="$t('forms.unlink_dreamer')"
                  @click="removeDreamer(dreamer.id)"
                >
                  <small class="m-75" style="color:tomato;">
                    <font-awesome-icon :icon="['fas', 'unlink']"
                    size="lg" />
                  </small>
                </div>
              </div>
              </template>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-alert
        variant="danger"
        :show="group === undefined"
      >
        <h4 class="alert-heading">
          Error fetching group data
        </h4>
        <div class="alert-body">
          No group found with this id. Go back to
          <b-link
            class="alert-link"
            :to="{ name: 'schools'}"
          >
            Schools List
          </b-link>
        </div>
      </b-alert>
    </template>
    
  </div>
</template>

<script>
import router from '@/router'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import InfoCard from './view/InfoCard.vue'
import {
  BRow, BCol, BButton,
    BCard, BCardTitle, BCardHeader, BCardBody, BMedia, 
    BMediaAside, BAvatar, BMediaBody, BAlert, BLink,VBTooltip
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components:{
    BRow, BCol, BAlert, BLink,BButton,
    BCard, BCardTitle, BCardHeader, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody,


    CustomBreadcrumb,
    InfoCard
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup(){
      return {
          avatarText
      }
  },
  data(){
    return {
      school: {},
      group: null,
    }
  },
  mounted(){
    let id = router.currentRoute.params.group_id;
    this.getData(id)
  },
  computed:{
    breadcrumb(){
       if(this.$store.getters['user/isSchool']){
          return [
              {
                text: this.$t('Schools'),
              },
              {
                text: this.school.name
              },
              {
                to: 'school-groups-list',
                text: this.$t('Groups'),
              },
              {
                text: this.$t('Detail'),
                active: true,
              },
            ]
          } else {

            return [
              {
                text: this.$t('Schools'),
                to: 'schools'
              },
              {
                to: 'school-view',
                params: {
                  id: this.school.id,
                },
                text: this.school.name
              },
              {
                to: 'school-groups-list',
                text: this.$t('Groups'),
              },
              {
                text: this.$t('Detail'),
                active: true,
              },
            ]
          }
    }
  },
  methods:{
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    async removeUser(user_id){
      console.log(user_id)
      let data = {
          'group_id': this.group.id,
          'tab': 'user',
          'user_id':user_id
        }
        await this.$http.post('school/group/unlink', data).then( response =>{
            console.log(response)
            if(response.data.status == 200){
              this.makeToast('success', this.$t('Success'), 'User unlinked successfully');
              this.getData(this.group.id)
            } else{
              this.makeToast('danger', this.$t('Error'), response.data.msg);
            }
        }).catch(error => {
            console.log("Err -> ", error)
        })
    },
    async removeDreamer(dreamer_id){
      console.log(dreamer_id)
      let data = {
          'group_id': this.group.id,
          'tab': 'dreamer',
          'dreamer_id':dreamer_id
        }
        await this.$http.post('school/group/unlink', data).then( response =>{
            console.log(response)
            if(response.data.status == 200){
              this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
              this.getData(this.group.id)
            } else{
              this.makeToast('danger', this.$t('Error'), response.data.msg);
            }
        }).catch(error => {
            console.log("Err -> ", error)
        })
    },
    async getData(id){
        console.log("loading")
        let data = {
          'id': id,
        }
        await this.$http.post('school/fetchGroup', data).then( response =>{
            console.log(response)
          
            this.school = response.data.school;
            this.group = response.data.group;
        }).catch(error => {
            console.log("Err -> ", error)
        })
    },
  }
}
</script>

<style>

</style>