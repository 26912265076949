var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.group)?[_c('custom-breadcrumb',{attrs:{"pageTitle":_vm.$t('Group'),"breadcrumb":_vm.breadcrumb}}),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('info-card',{attrs:{"school":_vm.school,"group":_vm.group},on:{"refetch-data":function($event){return _vm.getData(_vm.group.id)}}}),_c('b-card',{staticClass:"card-employee-task",attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(_vm._s(_vm.$t('forms.linked_teachers')))]),_c('b-button',{staticClass:"pointer p-0",attrs:{"variant":"link","to":{ 
                  name: 'school-groups-edit', 
                  params: { 
                    id: _vm.school.id, 
                    student_id: _vm.group.id, 
                    tab:1
                  } 
                }}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"PlusIcon","size":"18"}}),_vm._v(" "+_vm._s(_vm.$t('forms.link_teacher'))+" ")],1)],1),_c('b-card-body',[_vm._l((_vm.group.users),function(user){return [(user.linked)?_c('div',{key:user.user_id,staticClass:"employee-task d-flex justify-content-between align-items-center"},[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-75"},[_c('b-avatar',{attrs:{"size":"42","text":_vm.avatarText(user.name),"variant":"light-primary"}})],1),_c('b-media-body',{staticClass:"my-auto"},[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(user.name)+" ")]),_c('small',[_vm._v(_vm._s(user.email))])])],1),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.$t('forms.unlink_teacher')),expression:"$t('forms.unlink_teacher')",modifiers:{"hover":true,"left":true}}],staticClass:"d-flex align-items-center pointer",on:{"click":function($event){return _vm.removeUser(user.id)}}},[_c('small',{staticClass:"m-75",staticStyle:{"color":"tomato"}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'unlink'],"size":"lg"}})],1)])],1):_vm._e()]})],2)],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-card',{staticClass:"card-employee-task",attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(_vm._s(_vm.$t('Dreamers')))]),_c('b-button',{staticClass:"pointer p-0",attrs:{"variant":"link","to":{ 
                  name: 'school-groups-edit', 
                  params: { 
                    id: _vm.school.id, 
                    student_id: _vm.group.id, 
                    tab:2
                  } 
                }}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"PlusIcon","size":"18"}}),_vm._v(" "+_vm._s(_vm.$t('forms.add_model', {model: _vm.$t('models.dreamer')}))+" ")],1)],1),_c('b-card-body',[_vm._l((_vm.group.dreamers),function(dreamer){return [(dreamer.linked)?_c('div',{key:dreamer.id,staticClass:"employee-task d-flex justify-content-between align-items-center"},[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-75"},[_c('b-avatar',{attrs:{"size":"42","src":require('@/assets/images/avatar/AvatarSprite_'+(dreamer.avatar||'57')+'.png?v4')}})],1),_c('b-media-body',{staticClass:"my-auto"},[_c('h6',{staticClass:"mb-0"},[_c('b-link',{attrs:{"to":{ name: 'school-dreamers-view', params: { 
                        id: _vm.school.id,
                        dreamer_id: dreamer.id 
                      } }}},[_vm._v(" "+_vm._s(dreamer.name)+" ")])],1),_c('small',{staticClass:"text-muted "},[_vm._v(_vm._s(dreamer.age)+" "+_vm._s(_vm.$t('fields.years_old')))])])],1),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.$t('forms.unlink_dreamer')),expression:"$t('forms.unlink_dreamer')",modifiers:{"hover":true,"left":true}}],staticClass:"d-flex align-items-center pointer",on:{"click":function($event){return _vm.removeDreamer(dreamer.id)}}},[_c('small',{staticClass:"m-75",staticStyle:{"color":"tomato"}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'unlink'],"size":"lg"}})],1)])],1):_vm._e()]})],2)],1)],1)],1)]:[_c('b-alert',{attrs:{"variant":"danger","show":_vm.group === undefined}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Error fetching group data ")]),_c('div',{staticClass:"alert-body"},[_vm._v(" No group found with this id. Go back to "),_c('b-link',{staticClass:"alert-link",attrs:{"to":{ name: 'schools'}}},[_vm._v(" Schools List ")])],1)])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }